@import './mixins';

:root {
  --primary: #376f44;
  --primary-dark: #2f5e3a;
  --toastify-color-success: #376f44;
  --primary-transparent: rgba(#376f44, 95%);
  --secondary: #ffd338;
  --secondary-dark: #e9b600;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
*::bofore,
*::after {
  box-sizing: border-box;
}

html,
body {
  background-color: #fff;
  color: #686767;
  font-size: 15px;
  line-height: 1.5;
  max-width: 100vw;
  @include desktop-hd {
    font-size: 16px;
  }
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

img {
  max-width: 100%;
}

input,
button {
  font-size: inherit;
  font-family: inherit;
}
button:not([disabled]) {
  cursor: pointer;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity 300ms ease-in-out;
  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }
  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
}

.ReactModal__Content {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 200ms ease-in-out, transform 0.3s ease-in-out;
  overflow-x: hidden;
  overflow-y: auto;

  &.ReactModal__Content--after-open {
    opacity: 1;
    transform: translateY(0);
  }

  &.ReactModal__Content--before-close {
    opacity: 0;
    transform: translateY(50px);
  }
}
