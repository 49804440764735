@import '../../styles/mixins';

.bottomImage {
  aspect-ratio: 1;
  position: relative;
  @include tablet {
    aspect-ratio: 16 / 9;
  }
  @include desktop-lg {
    aspect-ratio: 4;
  }
  img {
    object-fit: cover;
  }
}
