@import '../../styles/mixins';

.callModalButton {
  background-color: var(--secondary);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  height: 50px;
  width: 50px;
  position: fixed;
  bottom: 30px;
  outline: none;
  right: 30px;
  z-index: 30;
  transition: 0.3s ease-in-out;
  &::before {
    background: url(/images/icon-phone.svg) center no-repeat;
    background-size: 24px 24px;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: brightness(0);
  }
  &:hover {
    background-color: var(--secondary-dark);
  }
}

.callModal {
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
  max-width: 90%;
  outline: none;
  padding: 30px;
  position: absolute;
  width: 700px;
  .callModalClose {
    background: url(/images/icon-mobile-menu-open.svg);
    background-size: cover;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 15px;
    width: 15px;
  }
  h2,
  h3 {
    color: #193034;
    margin: 0 0 20px;
  }
  h2 {
    font-size: 30px;
    font-weight: bold;
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.66;
  }
  p {
    font-size: 11px;
    text-align: justify;
  }
  .loader {
    background: rgba(#333, 20%);
    border-radius: 15px;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .callModalForm {
    margin: 30px 0;
    @include tablet {
      margin: 40px 30px;
    }
    .callModalFormGroup {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include tablet {
        flex-direction: row;
      }
    }
    .callModalFormInput {
      border: 1px solid #d5d5d5;
      border-radius: 10px 0 0 10px;
      display: block;
      flex: 1;
      font-family: inherit;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px;
      padding: 14px 30px 14px 10px;
      outline: none;
      width: 100%;
      @include tablet {
        margin-bottom: 0;
      }
    }
    button {
      @include button;
      display: flex;
      font-size: 16px;
      align-items: center;
      min-height: 50px;
      padding: 10px 15px;
      @include tablet {
        margin-left: -15px;
      }
      img {
        height: 20px;
        margin-right: 10px;
        width: 20px;
      }
    }
    .callModalFormError {
      font-size: 14px;
      margin-top: 2px;
      color: #c0392b;
      text-align: left;
    }
  }
}
