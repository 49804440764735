@import '../../styles/mixins';

.pageFooter {
  .pageFooterLayout {
    display: grid;
    padding: 50px 0 30px;
    grid-row-gap: 40px;

    @include mobile {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
    }

    @include tablet {
      grid-template-columns: 300px 1fr 1fr;
      padding-top: 75px;
    }
    @include desktop-lg {
      grid-column-gap: 50px;
    }
  }
  h4 {
    color: #193034;
    font-size: 16px;
    line-height: 1.33;
    margin-bottom: 20px;
  }
  ul {
    list-style: none;
    li + li {
      margin-top: 20px;
    }
  }
  .pageFooterAbout {
    @include mobile {
      grid-column: 1 / 3;
    }
    @include tablet {
      grid-column: unset;
    }
    .pageFooterLogo {
      aspect-ratio: 4.342105;
      max-width: 330px;
      margin: 0 auto 10px;
      position: relative;
      @include desktop {
        margin: 0 0 10px;
      }
    }
    p {
      font-size: 14px;
      text-align: center;
      @include tablet {
        text-align: left;
      }
    }
  }
  .pageFooterAddress {
    address {
      font-style: normal;
      line-height: 2;
    }
    .pageFooterContact {
      margin-top: 20px;
      a {
        @media (max-width: 450px) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
  .pageFooterCopyrights {
    border-top: 1px solid #f3f3f3;
    color: #193034;
    display: flex;
    flex-direction: column-reverse;
    padding: 20px 0;
    text-align: center;
    @include desktop {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .pageFooterLogos {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      column-gap: 30px;
      row-gap: 30px;
      margin-bottom: 30px;
      @include desktop {
        justify-content: flex-end;
        margin-bottom: 0;
      }
      @include desktop {
        column-gap: 50px;
        margin-right: 100px;
      }
      @include desktop-lg {
        margin-right: calc((1400px - 100vw) / 2);
      }
      @media (min-width: 1400px) {
        margin-right: 0;
      }
      .pageFooterAboutImage1,
      .pageFooterAboutImage2,
      .pageFooterAboutImage3 {
        display: block;
        max-width: 120px;
        position: relative;
        width: 100%;
      }
      .pageFooterAboutImage1 {
        aspect-ratio: 160 / 75;
      }
      .pageFooterAboutImage2 {
        aspect-ratio: 200 / 140;
        max-width: 100px;
      }
      .pageFooterAboutImage3 {
        aspect-ratio: 704 / 580;
        max-width: 100px;
      }
    }
  }
}
