@import '../../styles/mixins';

.pageHeader {
  background-color: #fff;
  padding: 15px 0;
  position: relative;
  .pageHeaderLayout {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .pageHeaderLogo {
    aspect-ratio: 4.347372;
    height: 60px;
    position: relative;
    @include desktop-lg {
      height: 90px;
    }
  }
  .mobileMenu {
    display: none;
  }
  .pageHeaderNav {
    align-items: center;
    display: flex;
    .pageHeaderSocialIcons {
      margin-left: 20px;
      a {
        display: inline-block;
        margin: 0 5px;
      }
    }
  }
  @media (max-width: $desktop-min-width - 1px) {
    .mobileMenu {
      background: url(/images/icon-mobile-menu.svg) center no-repeat;
      border: none;
      display: block;
      position: absolute;
      top: 50%;
      right: 15px;
      height: 36px;
      width: 36px;
      transform: translateY(-50%);
    }
    .pageHeaderNav {
      background-color: var(--primary);
      color: #fff;
      position: absolute;
      left: 0;
      top: 100%;
      right: 0;
      z-index: 10;
      opacity: 0;
      pointer-events: none;
      transform: translateY(30px) scale(0.95);
      transition: all 0.5s ease-in-out, transform 0.3s ease-in-out;
      .pageHeaderSocialIcons {
        display: none;
      }
    }
    &.pageHeader_open {
      .mobileMenu {
        background-image: url(/images/icon-mobile-menu-open.svg);
      }
      .pageHeaderNav {
        transform: translateY(0) scale(1);
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  .pageHeaderMenu {
    color: #171717;
    font-weight: 500;
    list-style: none;
    margin: 0;
    padding: 0;
    @media (max-width: $desktop-min-width - 1px) {
      color: #fff;
      padding: 20px;
      li {
        margin: 0 0 10px;
      }
    }
    @include desktop {
      display: flex;
      li {
        margin: 0 20px;
        a {
          &:hover {
            color: var(--primary);
          }
        }
      }
    }
  }
}
