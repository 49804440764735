@import '../../styles/mixins';

.cookiesLayer {
  // background-color: var(--primary-transparent);
  background-color: rgba($primary, 85%);
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  z-index: 100;

  &.cookiesLayer_visible {
    opacity: 1;
    pointer-events: all;
  }
  .cookiesLayerWrapper {
    padding: 15px 0;
    @include tablet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 50px;
      padding: 20px 0;
    }
    @include desktop {
      grid-gap: 100px;
    }
  }
  .cookiesLayerText {
    margin-bottom: 15px;
    a {
      text-decoration: underline;
    }
    @include desktop {
      margin-bottom: 0;
    }
  }
  .cookiesLayerButtons {
    text-align: right;
    button {
      @include button;
      padding: 5px 10px;
      font-size: 14px;
    }
  }
}
